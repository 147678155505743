import "@mdi/font/css/materialdesignicons.min.css";
import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import colors from "vuetify/lib/util/colors";
import zhHans from "vuetify/es5/locale/zh-Hans";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi",
  },
  theme: {
    // dark: true,
    themes: {
      light: {
        accent: colors.indigo.accent1,
      },
      dark: {
        secondary: "#121223",
        accent: colors.indigo.accent1,
      },
    },
  },
  lang: {
    locales: { zhHans },
    current: "zhHans",
  },
});
