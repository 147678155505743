<template>
  <v-footer
    padless
    class="transparent pt-0"
  >
    <v-card
      flat
      tile
      width="100%"
      class="text-center footer--safe-area transparent pt-0"
    >
      <v-card-text
        class="d-flex flex-column flex-sm-row align-center justify-center pt-0 pb-8"
        style="font-size: small"
      >
        <Vendor
          class="mr-2"
          style="font-size: small"
        />
      </v-card-text>

      <!--      <v-card-text-->
      <!--        class="d-flex flex-column flex-sm-row align-center justify-center caption pt-1 pb-2"-->
      <!--        style="font-size: small"-->
      <!--      >-->
      <!--        <div class="d-flex flex-row">-->
      <!--          <v-icon-->
      <!--            small-->
      <!--            left-->
      <!--          >-->
      <!--            mdi-domain-->
      <!--          </v-icon>-->

      <!--          <div>-->
      <!--            上海得斯教育科技有限公司-->
      <!--          </div>-->
      <!--        </div>-->

      <!--        <div>-->
      <!--          <a-->
      <!--            href="https://beian.miit.gov.cn/"-->
      <!--            target="_blank"-->
      <!--            rel="noreferrer noopener"-->
      <!--            class=" ml-1 text-decoration-none text-hoverable"-->
      <!--            style="color: inherit"-->
      <!--          >-->
      <!--            沪ICP备2021004001号-1-->
      <!--          </a>-->
      <!--        </div>-->
      <!--      </v-card-text>-->
    </v-card>
  </v-footer>
</template>

<script>
import environment from '@/utils/environment'
import Vendor from '@/components/foundation/Vendor'

export default {
  name: 'Footer',
  components: { Vendor },
  computed: {
    version () {
      return environment.version
    }
  }
}
</script>

<style scoped>
.condensed {
  letter-spacing: .075em !important;
}
  .clear-link {
    color: inherit !important;
    text-decoration: none !important;
  }
</style>
