import axios from 'axios'

const service = axios.create({
  baseURL: 'https://sigma.xacademy.cc/api',
  responseType: 'json',
  timeout: 30000
})

// service.interceptors.response.use(
//   function (response) {
//     store.commit('network/remove')
//
//     // if (!response.data) return malformedResponseMsg(response)
//
//     Logger.info('Service', 'successfully received response as', response)
//
//     autoRenewToken(response)
//
//     // Remain response intact if there's no error
//     return response
//   },
//   function (error) {
//     store.commit('network/remove')
//
//     Logger.error('Service', 'service request failed with request:', error.request, '| response:', error.response, '| config:', error.config)
//
//     // if (!error.response || !error.response.data) return malformedResponseMsg(error.response)
//
//     if (error.response) {
//       // The request was made and the server responded with a status code
//       // that falls out of the range of 2xx
//
//       if (error.response.status === 401 && !~error.config.url.indexOf('/auth')) {
//         redirectLogin()
//       } else {
//         if ([400, 401, 403, 404, 412, 500, 502, 503, 504].includes(error.response.status)) {
//           error.errorMessage = i18n.t(`error.service.network.status.${error.response.status}`)
//         } else {
//           error.errorMessage = `${i18n.t('error.service.network.status.default')} (${error.response.status || -1})`
//         }
//
//         let message
//         if (error.response.data && error.response.data.message) {
//           switch (error.response.data.message) {
//             case 'Invalid Credentials':
//               message = 'E-mail 或密码错误'
//               break
//             default:
//               message = error.response.data.message || (
//                 error.response.data.violations || error.response.data.violations.join('; ')
//               ) || error.response.data
//             // ? i18n.t('error.service.response.errorCode.' + error.response.data.status)
//             // : i18n.t('error.service.response.errorCode._undefined')
//           }
//         } else {
//           message = error.response.data || '(空返回体)'
//         }
//
//         error.errorMessage += `${i18n.t('meta.colon')}${message}`
//         // if (!error.response.data.errCode || error.response.data.errCode === -1) error.errorMessage += `${i18n.t('meta.colon')}${error.response.data.errMessage || '(Not Specified)'}`
//
//         if (!error.config.suppress || !error.config.suppress.includes(error.response.status)) {
//           store.commit('ui/createSnackbar', {
//             text: error.errorMessage,
//             type: 'error',
//             timeout: 15000
//           })
//         }
//       }
//     } else if (error.request) {
//       // The request was made but no response was received
//       // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
//       // http.ClientRequest in node.js
//       error.errorMessage = '网络连接疑似断开'
//     }
//
//     // Do something with response error
//     return Promise.reject(error)
//   })

export default service
