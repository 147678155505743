import Vue from 'vue'
import { VDialog, VBottomSheet } from 'vuetify/lib'

Vue.component('ResponsivePopup', {
  functional: true,
  render: function (h, ctx) {
    const component = ctx.parent.$vuetify.breakpoint.smAndUp
      ? VDialog
      : VBottomSheet

    return h(
      component,
      {
        props: ctx.props,
        on: ctx.listeners
      },
      [
        h(
          component,
          {
            props: ctx.props,
            on: ctx.listeners,
            slot: 'default'
          },
          ctx.children
        )
      ]
    )
  }
})
