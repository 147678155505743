<template>
  <v-app class="transition-all">
    <!-- <v-navigation-drawer
      v-model="drawer"
      app
      class="safe-area--navigation-drawer glossy-background transition-all"
      style="backdrop-filter: blur(40px);"
      width="300px"
    >
      <div class="d-flex flex-column text-center py-12">
        <Vendor />
        <div class="mt-1">
          探索者 Handbook
        </div>
      </div>

      <v-divider />

      <v-list
        dense
        nav
        expand
        class="safe-area--navigation"
      >
        <Navigation
          v-for="route in routes"
          :key="route.name"
          :route="route"
        />
      </v-list>

      <v-divider />

      <div
        class="d-flex flex align-center justify-center title degraded-opacity"
        style="height: 100px"
      >
        /42...
      </div>

      <v-divider
        class="mt-2 mb-1"
        style="margin-bottom: 128px !important"
      />
    </v-navigation-drawer> -->


    <v-app-bar
      app
      collapse
      class="transition-all glossy-background app-bar-card elevation-4"
      style="backdrop-filter: blur(40px)"
    >
      <!-- <v-app-bar-nav-icon @click.stop="drawer = !drawer" /> -->

      <div class="pl-4 font-neox-display user-select-none">
        X
      </div>
    </v-app-bar>

    <v-main>
      <v-container
        :fluid="$route.meta.fullWidth"
        class="fill-height"
      >
        <Background />
        <transition
          name="slide-fade"
          mode="out-in"
        >
          <router-view />
        </transition>
      </v-container>
      <Footer />
    </v-main>
  </v-app>
</template>

<script>
import Footer from '@/components/foundation/Footer'
// import Navigation from '@/components/foundation/Navigation'
import Vendor from '@/components/foundation/Vendor'
import Background from "@/components/foundation/Background";
import Navigation from "@/components/foundation/Navigation";

export default {
  name: 'App',
  components: {
    Navigation,
    Background,
    Vendor,
    // Navigation,
    Footer
  },
  data() {
    return {
      drawer: false
    }
  },
  computed: {
    routes() {
      return this.$router.options.routes;
    }
  },
}
</script>

<style scoped>
.floating-nav {
  position: fixed;
  top: 1rem;
  left: 1rem;
  z-index: 100;
}

.app-bar-card {
  clip-path: polygon(
      0 0,
      0 100%,
      calc(100% - 1.5rem) 100%,
      100% calc(100% - 1.5rem),
      100% 0
  );
  border-radius: 0 !important;
}
</style>
