<template>
  <div class="d-flex flex-column align-center justify-center flex px-4">
    <FullscreenPanel
      title="运行手册"
      subtitle="Explorers' Handbook"
    />

    <!-- <Preloadable
      :loading="loading"
      :error="error"
    >
      <div class="d-flex flex-column flex">
        <h1 class="heading mb-2">
          lambda://
        </h1>
        
        <v-card
          class="backdrop-blur background-transparent d-flex align-center pa-4"
          :to="{name: 'Cabinet'}"
        >
          <v-icon>
            mdi-file-cabinet
          </v-icon>
          <div class="d-flex flex-column ml-2">
            <div class="title">
              广播数据中枢
            </div>
            <div class="subtitle-2 degraded-opacity">
              包含地理位置指引系统与日程表等数据
            </div>
          </div>
        </v-card>

        <v-card
          class="backdrop-blur background-transparent d-flex align-center pa-4 mt-2"
          :to="{name: 'Roster'}"
        >
          <v-icon>
            mdi-account-multiple
          </v-icon>
          <div class="d-flex flex-column ml-2">
            <div class="title">
              X Roster
            </div>
            <div class="subtitle-2 degraded-opacity">
              以别样方式探索你周围的领航员，他们将在你需要时提供所需协助
            </div>
          </div>
        </v-card>

        <div
          v-for="article in articles"
          :key="article.id"
          class="d-flex flex-column align-center justify-center full-width mt-6"
        >
          <span
            v-marked
            class="font-neox-text markdown-content full-width markdown-body"
            v-html="article.content"
          />
        </div>

        <div class="d-flex flex-column align-center justify-center text-center py-6 full-width mt-12 monospace degraded-opacity user-select-none border-outlined rounded">
          <div class="mb-2">
            // 以下无正文 //
          </div>
          <div>:wq</div>
        </div>
      </div>
    </Preloadable> -->
  </div>
</template>

<script>
import service from "@/utils/service";
import Preloadable from "@/components/foundation/Preloadable";
import FullscreenPanel from "@/components/foundation/FullscreenPanel";

export default {
  name: "Home",
  components: {FullscreenPanel, Preloadable},
  data() {
    return {
      articles: [],
      loading: true,
      error: null
    }
  },
  created () {
    // this.update()
  },
  methods: {
    // update() {
    //   this.loading = true
    //   service.get('/categories/xa21-shanghai')
    //     .then(({data}) => {
    //       this.articles = data.articles
    //     })
    //     .catch(err => {
    //       this.error = err
    //     })
    //     .finally(() => {
    //       this.loading = false
    //     })
    // }
  },
};
</script>
