<template>
  <v-list-item
    v-if="!route.children || route.meta.forceSingle"
    :key="route.name"
    :class="(route.name || route.children[0].name) === $route.name ? activeClass : ''"
    :two-line="!!route.meta.twoLine"
    @click="$router.push({path: route.path})"
  >
    <v-list-item-icon>
      <v-icon v-text="route.meta.icon" />
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title>
        {{ route.meta.title }} &nbsp; <v-icon
          v-if="!route.component && !route.meta.forceSingle"
          small
        >
          mdi-open-in-new
        </v-icon>
      </v-list-item-title>
      <v-list-item-subtitle
        v-if="!!route.meta.twoLine"
        class="overline"
      >
        {{ route.meta.twoLine }}
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
  <v-list-group
    v-else
    :key="route.name"
    :value="route.meta.active"
    :prepend-icon="route.meta.icon"
    :color="`blue ${dark ? 'lighten-1' : 'darken-2'}`"
    no-action
  >
    <template v-slot:activator>
      <v-list-item-title>{{ route.meta.title }}</v-list-item-title>
    </template>

    <v-list-item
      v-for="child in route.children.filter(el => !el.meta.hide || el.name === $route.name)"
      :key="child.name"
      :class="child.name === $route.name ? activeClass : ''"
      @click="$router.push({name: child.name})"
    >
      <v-list-item-title>
        <span class="mr-1 grey--text">—</span> {{ child.meta.title }} &nbsp; <v-icon
          v-if="!child.components && !child.meta.forceSingle"
          small
        >
          mdi-open-in-new
        </v-icon>
      </v-list-item-title>

      <v-list-item-icon>
        <v-icon v-text="child.meta.icon" />
      </v-list-item-icon>
    </v-list-item>
  </v-list-group>
</template>

<script>

export default {
  name: 'Navigation',
  props: {
    route: {
      type: Object,
      required: true
    }
  },
  computed: {
    activeClass () {
      return {
        'v-list-item--active': true,
        'white--text': this.$vuetify.theme.dark
      }
    }
  },
  methods: {
    navigate (route) {
      this.$router.push({
        name: route.name
      })
    }
  }
}
</script>
