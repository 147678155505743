<template>
  <span
    class="techx-logo font-neox font-size--large"
  >
    X ACADEMY
  </span>
</template>

<script>
export default {
  name: 'Vendor',
  props: {
    inline: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
.techx-logo {
  user-select: none;
  -webkit-user-drag: none;
  -webkit-touch-callout: none;
}
</style>
