<template>
  <v-fade-transition leave-absolute>
    <slot
      v-if="$scopedSlots['preloader'] && loading"
      v-bind="{ preloadText }"
      name="preloader"
    />
    <div
      v-else-if="loading"
      key="preset-preloader"
      class="d-flex flex-column py-4 align-center justify-center"
    >
      <v-progress-circular indeterminate />
      <span
        class="mt-2"
        v-text="preloadText"
      />
    </div>
    <slot
      v-else-if="$scopedSlots['error'] && error"
      v-bind="{ error }"
      name="error"
    />
    <div
      v-else-if="error"
      key="preset-error"
      class="d-flex flex-column py-4 align-center justify-center"
    >
      <v-icon>
        mdi-alert-circle
      </v-icon>
      <span
        class="mt-2"
        v-text="error"
      />
    </div>
    <slot
      v-else
    />
  </v-fade-transition>
</template>

<script>
export default {
  name: 'Preloadable',
  props: {
    loading: {
      type: Boolean,
      default: () => false
    },
    error: {
      type: [String, Error],
      default: () => null
    },
    preloadText: {
      type: String,
      default: () => '正在加载...'
    }
  }
}
</script>

<style scoped>

</style>
