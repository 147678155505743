const isProduction = process.env.NODE_ENV === 'production'

export default {
  is: {
    get production () { return isProduction },
    get development () { return !isProduction },
  },
  adopter (obj) { return obj[process.env.NODE_ENV] },
  isMobile () {
    return /iPhone|iPad|iPod|Android/i.test(window.navigator.userAgent)
  }
}
