import Fuse from 'fuse.js'

class SearchEngine {
  constructor ({ data, keys }) {
    this.engineOptions = {
      includeScore: true,
      ignoreLocation: true,
      useExtendedSearch: true,
      threshold: 0.45
    }
    this.ready = new Promise(() => {})
    this.keys = keys
    this.update(data)
  }

  update (data) {
    this.data = data || []
    this.updateEngine()
  }

  updateEngine () {
    const docs = this.data
    this.engine = new Fuse(docs, {
      ...this.engineOptions,
      keys: this.keys
    })
  }

  query (keyword) {
    if (!this.engine) throw new Error('search engine called before not initialized')
    console.log('SearchEngine', 'querying result with keyword', keyword)
    return this.engine.search(keyword)
  }
}

export default SearchEngine
