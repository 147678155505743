<template>
  <v-card
    class="position-relative"
    v-bind="$attrs"
  >
    <v-card-title
      v-if="viewer.enabled === 'dialog'"
      class="pa-0"
    >
      <v-img
        ref="heroImage"
        :src="`${(viewer.content.avatar || {}).url}!display`"
        :alt="viewer.content.name"
        width="100%"
        height="450"
        aspect-ratio="1"
        style="background: rgba(0, 0, 0, .9); transition: all .065s cubic-bezier(0.165, 0.84, 0.44, 1);"
        gradient="to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 80%, rgba(0, 0, 0, .8)"
      >
        <div
          class="d-flex flex align-end px-4 pb-8 display-1 white--text fill-height"
          style="text-shadow: 0 0 5px rgba(0, 0, 0, .5)"
        >
          {{ viewer.content.name }}
        </div>
      </v-img>
    </v-card-title>

    <v-card-title v-else-if="viewer.enabled === 'tooltip'">
      <v-avatar
        size="40"
        class="mr-2"
      >
        <v-img
          :src="`${(viewer.content.avatar || {}).url}!display`"
          :alt="viewer.content.name"
        />
      </v-avatar>
      {{ viewer.content.name }}
    </v-card-title>

    <v-btn
      v-if="viewer.enabled === 'dialog'"
      icon
      style="position: absolute; right: 1rem; top: 1rem; z-index: 5; text-shadow: 0 0 2px rgba(255, 255, 255, .5); pointer-events: auto"
      @click.native.stop="$emit('close')"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>

    <v-fade-transition>
      <v-card-text
        v-if="viewer.enabled === mode"
        v-scroll.self="onScroll"
        class="color–white-force pt-2"
        :class="{'mt-4': mode === 'dialog'}"
      >
        <div
          v-marked
          class="markdown-content-inline markdown-body"
          :class="{'truncated': mode === 'tooltip'}"
          v-html="viewer.content.introduction"
        />

        <div class="d-flex flex align-center">
          <v-img
            v-if="viewer.content.qrcode"
            contain
            height="160"
            width="160"
            :src="`${(viewer.content.qrcode || {}).url}`"
            :alt="viewer.content.name"
            class="pa-3 mt-6 rounded qr-image"
          >
            <template #placeholder>
              <div class="d-flex flex align-center justify-center fill-height">
                <v-progress-circular indeterminate />
              </div>
            </template>
          </v-img>

          <div
            v-if="mode === 'tooltip'"
            class="d-flex flex-column mx-4 fill-height flex"
          >
            <v-icon>
              mdi-cursor-default-click
            </v-icon>

            <div class="mt-2 text-center">
              轻击以查看<br>更多信息
            </div>
          </div>
        </div>

        <v-divider
          v-if="mode === 'dialog'"
          class="mt-8"
        />
      </v-card-text>
    </v-fade-transition>
  </v-card>
</template>

<script>
export default {
  name: "RosterDetail",
  props: {
    viewer: {
      type: Object,
      required: true
    },
    mode: String
  },
  methods: {
    onScroll(e) {
      const scrollRatio = Math.max(0, Math.min(e.target.scrollTop / 60, 1))

      const height = 450 + scrollRatio * -150

      this.$refs.heroImage.$el.style.height = `${height}px`
    }
  },
}
</script>

<style lang="scss">
.qr-image {
  background: linear-gradient(to bottom right, rgba(0, 0, 0, 1), rgba(0, 0, 0, .9));

  & .v-image__image {
    //filter: contrast(0);
    //mix-blend-mode: color-burn;
  }
}

.truncated {
  max-height: 210px;
  overflow: hidden;

  mask: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 1) 150px, rgba(0, 0, 0, 0));
}
</style>