<template>
  <div
    class="d-flex flex-column align-center justify-center text-center flex"
    style="height: 100vh"
  >
    <div style="height: 300px" />
    <span class="display-2">{{ title }}</span>
    <span class="title mt-1">{{ subtitle }}</span>
    <span class="subtitle-1 mt-12 degraded-opacity d-flex align-center font-weight-bold">
      <v-icon left>
        mdi-clock
      </v-icon>
      X Academy 2022 筹备中...
    </span>
  </div>
</template>

<script>
export default {
name: "FullscreenPanel",
  props: {
    title: String,
    subtitle: String,
  },
}
</script>

<style scoped>

</style>