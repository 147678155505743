import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import './components/functional/ResponsivePopup'
import "github-markdown-css/github-markdown.css";

import marked from 'marked';

import "./styles/index";

Vue.config.productionTip = false;

marked.setOptions({
  breaks: true,
  silent: true
})

Vue.directive('marked', function (el) {
  el.innerHTML = marked(el.innerHTML);

  [...el.querySelectorAll('a')].forEach(a => {
    // avoid interfering with form input tab focus
    a.setAttribute('tabindex', '-1')
    const href = a.getAttribute('href')
    if (href && href.charAt(0) !== '#') {
      // make external links open in new tab
      a.setAttribute('target', '_blank')
      a.setAttribute('rel', 'noreferrer noopener')
    }
  })
})


new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
