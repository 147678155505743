<template>
  <div class="full-screen-image image-transition">
    <v-img
      ref="background"
      v-bind="wrapper"
      position="center center"
    >
      <template #placeholder>
        <div
          class="d-flex fill-height full-width"
          v-bind="placeholder"
        />
      </template>
      <v-overlay
        :value="!!$route.meta.background"
        absolute
        :color="$route.meta.background"
        opacity="1"
      />
    </v-img>
  </div>
</template>

<script>
export default {
  name: 'Background',
  data () {
    return {
      wrapper: {
        src: 'https://portal.xastatic.com/common/background.jpg'
      },
      placeholder: {
        style: {
          background: '#e9e9e9'
        }
      }
    }
  },
  mounted () {
    document.addEventListener('scroll', this.scrollHandler, {
      passive: true
    })
  },
  beforeDestroy () {
    document.removeEventListener('scroll', this.scrollHandler, {
      passive: true
    })
  },
  methods: {
    scrollHandler() {
      const percentage = Math.min(1, window.scrollY / window.innerHeight)
      const self = this
      requestAnimationFrame(() => {
        self.$refs.background.$el.style.filter = `blur(${percentage * 25}px) saturate(${1 + percentage * -0.1}) brightness(${1 + percentage * 0.1})`
        self.$refs.background.$el.style.transform = `scale(${1 + percentage * 0.25})`
      })
    }
  },
}
</script>

<style scoped lang="scss">
@import "src/styles/global";

.full-screen-image {
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  z-index: -1;
}
.full-screen-image > .v-image {
  height: 100vh;
  object-fit: cover;
}

.image-transition > .v-image > .v-image__image {
  @apply transition-all;
}
</style>
