import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home";
import Roster from "@/views/Roster";
import Cabinet from "@/views/Cabinet";
// import Roster from "@/views/Roster";
// import Article from "@/views/Article";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: 'Home',
    component: Home,
    meta: {
      title: '运行手册',
      icon: 'mdi-home'
    }
  },
  // {
  //   path: "/roster",
  //   name: 'Roster',
  //   component: Roster,
  //   meta: {
  //     title: 'X Roster',
  //     icon: 'mdi-account-multiple'
  //   }
  // },
  // {
  //   path: "/cabinet",
  //   name: 'Cabinet',
  //   component: Cabinet,
  //   meta: {
  //     title: '广播数据中枢',
  //     icon: 'mdi-file-cabinet'
  //   }
  // },
  {
    path: '*',
    beforeEnter (to, from, next) {
      return next('/')
    },
    meta: {
      title: 'Not Found',
      icon: 'mdi-cancel',
      hide: true
    }
  }
  // {
  //   path: "/roster",
  //   component: Roster,
  //   meta: {
  //     title: 'X Roster',
  //     icon: 'mdi-home'
  //   }
  // },
  // {
  //   path: "/entry/:entryId",
  //   component: Article,
  //   meta: {
  //     title: '项',
  //     icon: 'mdi-file',
  //     hide: true
  //   }
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
