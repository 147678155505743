<template>
  <v-container class="d-flex flex-column flex">
    <!--    <div style="display: flex; justify-content: center">-->
    <!--      <v-text-field-->
    <!--        v-model="searchValue"-->
    <!--        solo-->
    <!--        prepend-icon="mdi-magnify"-->
    <!--        :loading="isSearchLoading"-->
    <!--        :disabled="isSearchLoading"-->
    <!--        label="Search 搜索"-->
    <!--        style="margin: 10px 40px 0 40px; max-width: 400px"-->
    <!--        clearable-->
    <!--      />-->
    <!--    </div>-->

    <!--    <v-sheet-->
    <!--      class="py-4 px-4 title backdrop-blur"-->
    <!--      rounded-->
    <!--      elevation="8"-->
    <!--      color="white"-->
    <!--    >-->
    <!--      -->
    <!--    </v-sheet>-->
    <!--    -->

    <ResponsivePopup
      :value="viewer.enabled === 'dialog'"
      max-width="500"
      content-class="position-relative"
      overlay-color="rgba(0, 0, 0, .88)"
      scrollable
      @input="e => viewer.enabled = e"
    >
      <RosterDetail
        v-touch="{
          down: () => closeViewer
        }"
        flat
        :viewer="viewer"
        mode="dialog"
        @close="closeViewer"
      />
    </ResponsivePopup>

    <v-text-field
      v-model="search"
      solo
      prepend-icon="mdi-magnify"
      clearable
      full-width
      solo-inverted
      label="搜索"
    />

    <v-fade-transition>
      <div
        v-show="viewer.enabled === 'tooltip'"
        ref="floatingPanel"
        class="floating-panel-wrapper"
      >
        <RosterDetail
          flat
          class="floating-panel"
          width="400"
          dark
          :viewer="viewer"
          mode="tooltip"
        />
      </div>
    </v-fade-transition>
    
    <!-- 正常显示 -->
    <Preloadable
      :loading="loading"
      :error="error"
    >
      <div class="d-flex flex flex-column">
        <div
          v-for="item in processedItems"
          :key="item.id"
          class="mt-8"
        >
          <div class="mt-2 mb-4 title">
            {{ item.department.name }}
          </div>

          <v-row style="justify-content: center">
            <v-col
              v-for="employee in item.items"
              :key="employee.id"
              cols="12"
              sm="4"
              md="4"
              lg="3"
              xl="2"
            >
              <v-card
                v-ripple
                class="ma-0 overflow-hidden"
                height="170px"
                @click="e => openViewer(employee, e, true)"
                @mousemove="e => openViewer(employee, e)"
                @mouseleave="viewer.enabled === 'tooltip' && closeViewer()"
              >
                <v-img
                  :src="`${(employee.avatar || {}).url}!thumbnail`"
                  :alt="employee.name"
                  aspect-ratio="1"
                  height="170px"
                  gradient="to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, .5)"
                >
                  <span class="white--text title d-flex fill-height align-end justify-center font-weight-bold pb-4">
                    {{ employee.name }}
                  </span>
                </v-img>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </div>
    </Preloadable>
  </v-container>
</template>

<script>
import service from "@/utils/service";
import Preloadable from "@/components/foundation/Preloadable";
import SearchEngine from "@/utils/SearchEngine";
import RosterDetail from "@/components/foundation/RosterDetail";

export default {
  name: 'Roster',
  components: {RosterDetail, Preloadable },
  data: () => {
    return {
      items: [],
      // searchValue: null,
      loading: true,
      error: null,
      viewer: {
        enabled: false,
        content: null
      },
      search: '',
      searchEngine: null
      // isSearchLoading: true,
      // plainEmployeeList: [],
    };
  },
  computed: {
    processedItems() {
      let result
      if (this.searchEngine && this.search !== '') {
        const query = this.searchEngine.query(this.search)
        query.sort((a, b) => a.score - b.score)
        result = query.map(el => ({
          ...el,
          ...el.item
        }))
      } else {
        result = this.items
      }

      const departments = {}

      result.forEach(el => {
        if (departments[el.department.id]) {
          departments[el.department.id].items.push(el)
        } else {
          departments[el.department.id] = {
            department: el.department,
            items: [el]
          }
        }
      })

      const processedItems = Object.entries(departments)
        .map(el => ({
          department: el[1].department,
          items: el[1].items
        }))

      processedItems.sort((a, b) => b.department.priority - a.department.priority)

      return processedItems

    }
  },
  created() {
    this.update()

    document.addEventListener('resize', this.closeViewer, { passive: true })
    document.addEventListener('scroll', this.closeViewer, { passive: true })
  },
  beforeDestroy() {
    document.removeEventListener('resize', this.closeViewer, { passive: true })
    document.removeEventListener('scroll', this.closeViewer, { passive: true })
  },
  methods: {
    update() {
      service.get("/departments")
          .then((res) => {
            const departments = res.data.sort((a, b) => {
              return b.priority - a.priority;
            });

            const people = []

            for (let department of departments) {
              department.employees.forEach((el) => {
                people.push(
                    {
                      ...el,
                      department: {
                        id: department.id,
                        name: department.name,
                        priority: department.priority
                      }
                    }
                )
              })
            }

            this.searchEngine = new SearchEngine({
              data: people,
              keys: [
                'name',
                'introduction'
              ]
            })

            this.items = people
          })
          .catch(err => {
            this.error = err
          })
          .finally(() => {
            this.loading = false;
          })
    },
    openViewer(content, e, forceDialog) {
      this.viewer.enabled = forceDialog ? 'dialog' : 'tooltip'
      if (!this.viewer.content || this.viewer.content.id !== content.id) this.viewer.content = content

      const CARD_WIDTH = 200

      const floatingPanelRef = this.$refs.floatingPanel

      const boundingBox = floatingPanelRef.getBoundingClientRect()

      if (this.$vuetify.breakpoint.xsOnly) {
        this.$nextTick(() => {
          floatingPanelRef.classList.add('small-screen')
        })
      } else {
        requestAnimationFrame(() => {
          const MAX_Y = window.innerHeight - boundingBox.height / 2

          const topClipped = Math.min(
              MAX_Y,
              Math.max(
                  boundingBox.height / 2,
                  e.y
              )
          )

          const leftClipped = Math.min(
              window.innerWidth - boundingBox.width,
              Math.max(
                  0,
                  e.x - CARD_WIDTH
              )
          )
          
          floatingPanelRef.style.top = `${topClipped}px`
          floatingPanelRef.style.left = `${leftClipped}px`
          floatingPanelRef.classList.remove('small-screen')
        })
      }
    },
    closeViewer() {
      this.viewer.enabled = false
    }
  },
};
</script>

<style lang="scss">
.clip {
  clip-path: polygon(
    0 0,
    0 100%,
    calc(100% - 1.5rem) 100%,
    100% calc(100% - 1.5rem),
    100% 0
  );
  max-width: 300px;
}

.color–white-force .markdown-body {
  color: inherit !important;
  //h1, h2, h3, h4, h5, h6, p, li, blockquote, span {
  //  color: white !important
  //}
}

.floating-panel-wrapper {
  position: fixed;
  z-index: 4;
  user-select: none;
  pointer-events: none;
}
.floating-panel {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;

  background: rgba(0, 0, 0, .8) !important;

  backdrop-filter: blur(40px);

  clip-path: polygon(
      0 0,
      0 100%,
      calc(100% - 1.5rem) 100%,
      100% calc(100% - 1.5rem),
      100% 0
  );

  .small-screen & {
    position: fixed !important;
    bottom: 0 !important;
    left: 0 !important;
    transform: none !important;
    padding-bottom: 60px !important;
    background: linear-gradient(135deg, rgba(0, 0, 0, .98), rgba(0, 0, 0, .9)) !important;
    border-radius: 0 !important;
    clip-path: none !important;
    width: 100% !important;
  }
}
</style>
