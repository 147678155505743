<template>
  <div class="d-flex flex-column align-center justify-center flex px-4">
    <FullscreenPanel
      title="广播数据中枢"
      subtitle="Broadcasting Message Bus"
    />

    <Preloadable
      :loading="loading"
      :error="error"
    >
      <div class="full-width">
        <span
          v-marked
          class="font-neox-text markdown-content full-width markdown-body"
          v-html="data"
        />

        <h2 class="mb-4 mt-6">
          美食地图
        </h2>
        <img
          src="https://portal.xastatic.com/handbook/b8f6089f6801221c161fe8b1671e2a9e29350cdc.jpg"
          width="100%"
          alt="美食地图"
        >
      </div>
    </Preloadable>
  </div>
</template>

<script>
import FullscreenPanel from "@/components/foundation/FullscreenPanel";
import service from "@/utils/service";
import Preloadable from "@/components/foundation/Preloadable";
export default {
name: "Cabinet",
  components: {Preloadable, FullscreenPanel},
  data() {
    return {
      data: null,
      loading: true,
      error: null
    }
  },
  created() {
    this.update()
  },
  methods: {
    update() {
      service.get('/articles/xa21-shanghai-cabinet')
        .then(({data}) => {
          this.data = data.content
        })
      .catch(err => {
        this.error = err
      })
      .finally(() => {
        this.loading = false
      })
    }
  },
}
</script>

<style scoped>

</style>